.autocomplete-wsi-thumbnail-container {
  width: 48px !important;
  height: 48px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.autocomplete-wsi-thumbnail-skeleton {
  width: 48px !important;
  height: 48px !important;
}

.autocomplete-wsi-thumbnail-skeleton > .ant-skeleton-image-svg {
  width: 24px !important;
  height: 24px !important;
}
