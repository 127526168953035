.icon-dot-annotation-statistic {
  display: inline-flex;
  align-self: center;
}

.icon-dot-annotation-statistic svg {
  height: 1em;
  width: 1em;
  fill: currentColor;
}

.icon-dot-annotation-statistic.baseline svg {
  top: 0.125em;
  position: relative;
}

.custom-statistic-small .ant-statistic-title {
  font-size: 14px;
}

.custom-statistic-small .ant-statistic-title .icon-dot-annotation-statistic.baseline svg {
  top: 0.2em;
  position: relative;
}

.custom-statistic-small .ant-statistic-content-value-int {
  font-size: 16px;
}

.custom-statistic-small .ant-statistic-content {
  line-height: 1;
}

.custom-statistic-small .ant-statistic-content .ant-statistic-content-suffix {
  font-size: 16px;
}

.custom-statistic-hand-cursor {
  cursor: pointer;
}

.custom-statistic-disabled {
  opacity: 0.2;
}
