.custom-roi-tool {
  left: 0.5em;
}

#custom-roi-tool-button {
  width: 20px;
  height: 20px;
  font-size: 12px;
}

#custom-roi-tool-button-focus {
  background-color: #d1a5ff !important;
  width: 20px;
  height: 20px;
  font-size: 12px;
}
