.image-grid .ant-skeleton-image {
  width: 100% !important;
  height: 150px !important;
  background-color: rgba(190, 190, 190, 0.1) !important;
}

.image-grid .thumbnail {
  height: 100px !important;
}

.image-grid .ant-card.active {
  box-shadow: 0 1px 2px -2px rgb(99 0 204 / 16%), 0 3px 6px 0 rgb(99 0 204 / 12%), 0 5px 12px 4px rgb(99 0 204 / 9%);
  border-left: 3px solid #6300cc;
  padding-left: 1px;
}
