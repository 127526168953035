.thumbnail-container {
  display: flex !important;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.thumbnail-container > .ant-spin-nested-loading {
  width: 100%;
  height: 100%;
}

.thumbnail-container > .ant-spin-nested-loading .ant-spin-container {
  width: 100%;
  height: 100%;
}

.ant-skeleton {
  width: 100% !important;
  height: 100%;
}

.thumbnail-container > .ant-spin-nested-loading > .ant-spin-container > .ant-skeleton-element > .ant-skeleton-image {
  width: 100%;
  height: 100% !important;
}
