#map-container {
  height: 100%;
  width: 100%;
}

#map {
  height: 100%;
  width: 100%;
}

.ol-control button {
  background-color: #6300cc !important;
  color: #ffffff !important;
}
