.login {
  height: 100vh;
  background-size: cover;
  display: grid;
  align-items: center;
  justify-content: center;
}

.ant-form-item-label {
  padding-bottom: 8px;
}
